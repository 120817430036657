import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import GifVideo from '../../components/VideoPlayer'

import ButtonSelector from '../../components/ButtonSelector'

const useStyles = (props) =>
    makeStyles((theme) => ({
        imageBox2: {
            textAlign: 'right',
        },
        image: {
            [theme.breakpoints.up('sm')]: {
                minHeight: '489px',
            },
        },
        video: {
            width: '100%',
            height: 'auto',
        },
        buttonWrapper: {
            [theme.breakpoints.up('xs')]: {
                marginLeft: '5%',
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '15%',
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '36%',
            },
        },
        buttonWrapper2: {
            [theme.breakpoints.up('xs')]: {
                marginLeft: '5%',
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '10%',
            },
        },
        contentRow: {
            paddingLeft: '36%',
            paddingTop: '7%',
            paddingRight: '4%',
            [theme.breakpoints.down('lg')]: {
                padding: '7% 8% 0 15%',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8% 4% 0 5%',
            },
        },
        contentRowReverse: {
            paddingLeft: '10%',
            paddingTop: '7%',
            paddingRight: '36%',
            [theme.breakpoints.down('lg')]: {
                padding: '7% 14% 0 10%',
            },
            [theme.breakpoints.down('sm')]: {
                padding: '8% 4% 0 5%',
            },
        },

        header: {
            fontSize: '1.125rem',
            fontWeight: 700,
            color: '#75CCBD',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        title: {
            fontSize: '1.75rem',
            fontWeight: 600,
            paddingBottom: '5%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        text: {
            fontSize: '1.5rem',
            fontWeight: 400,
            color: '#0F0518',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        buttonPrimary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            margin: '50px 0px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        buttonSecondary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            margin: '50px 0px',
            background:
                'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
            '&:hover': {
                background:
                    'linear-gradient(152.51deg, rgba(20, 255, 215, 0.5) -64.25%, rgba(117, 204, 189, 0.5))',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
    }))

const getOrientation = (input) =>
    input === 'Content(left) -> Image(right)' ? 'row' : 'row-reverse'

const getPadding = (input, classes, option) => {
    const hasHeader = Boolean(option.header)
    const hasTitle = Boolean(option.title)
    const hasText = Boolean(option.text)
    const hasButton = Boolean(option.button)
    const hasImage = Boolean(option.image)

    const image = getImage(option.image)

    if (input === 'Content(left) -> Image(right)') {
        return (
            <React.Fragment>
                <Grid item xs={12} md={6}>
                    <Box className={classes.contentRow}>
                        {hasHeader && (
                            <Box className={classes.header}>
                                {option.header}
                            </Box>
                        )}
                        {hasTitle && (
                            <Box className={classes.title}>{option.title}</Box>
                        )}
                        {hasText && (
                            <Box className={classes.text}>
                                {option.text.text}
                            </Box>
                        )}
                    </Box>
                    {hasButton &&
                        option.button.map((button) => {
                            const buttonColor = (color) => {
                                if (color === 'Orange') {
                                    return classes.buttonPrimary
                                }
                                return classes.buttonSecondary
                            }
                            const theButtonColor = buttonColor(
                                button.buttonColor
                            )
                            const hasTagClass = button.tagClass
                                ? button.tagClass
                                : ''
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    // md={6}
                                    lg={4}
                                    className={classes.buttonWrapper}
                                >
                                    <ButtonSelector
                                        className={`${theButtonColor} ${hasTagClass}`}
                                        data={button}
                                    >
                                        {button.label}
                                    </ButtonSelector>
                                </Grid>
                            )
                        })}
                </Grid>
                <Grid item xs={12} md={6}>
                    {hasImage &&
                    option.image.file.contentType === 'video/mp4' ? (
                        <GifVideo
                            src={option.image.file.url}
                            className={classes.video}
                        />
                    ) : (
                        <Box className={classes.imageBox1}>
                            <GatsbyImage
                                image={image}
                                alt={option.title}
                                className={classes.image}
                            />
                        </Box>
                    )}
                </Grid>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <Box
                    className={classes.contentRowReverse}
                    display="flex"
                    flexDirection="column"
                    justifyContent="left"
                    minHeight="125px"
                >
                    {hasHeader && (
                        <Box className={classes.header}>{option.header}</Box>
                    )}
                    {hasTitle && (
                        <Box className={classes.title}>{option.title}</Box>
                    )}
                    {hasText && (
                        <Box className={classes.text}>{option.text.text}</Box>
                    )}
                </Box>
                {hasButton &&
                    option.button.map((button) => {
                        const buttonColor = (color) => {
                            if (color === 'Orange') {
                                return classes.buttonPrimary
                            }
                            return classes.buttonSecondary
                        }
                        const theButtonColor = buttonColor(button.buttonColor)
                        const hasTagClass = button.tagClass
                            ? button.tagClass
                            : ''
                        return (
                            <Grid
                                item
                                xs={6}
                                // md={6}
                                lg={4}
                                className={classes.buttonWrapper2}
                            >
                                <ButtonSelector
                                    className={`${theButtonColor} ${hasTagClass}`}
                                    data={button}
                                >
                                    {button.label}
                                </ButtonSelector>
                            </Grid>
                        )
                    })}
            </Grid>
            <Grid item xs={12} md={6} className={classes.imageWrap}>
                {hasImage && option.image.file.contentType === 'video/mp4' ? (
                    <GifVideo
                        src={option.image.file.url}
                        className={classes.video}
                    />
                ) : (
                    <Box className={classes.imageBox2}>
                        <GatsbyImage
                            image={image}
                            alt={option.title}
                            className={classes.image}
                        />
                    </Box>
                )}
            </Grid>
        </React.Fragment>
    )
}

const Mosaic = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    return (
        <Box>
            {data.content.map((option) => {
                const direction = getOrientation(option.orientation)

                return (
                    <Box
                        style={{ backgroundColor: 'rgba(238, 233, 229, 0.5)' }}
                    >
                        <Container maxWidth="xl" style={{ padding: 0 }}>
                            <Grid container direction={direction}>
                                {getPadding(
                                    option.orientation,
                                    classes,
                                    option
                                )}
                            </Grid>
                        </Container>
                    </Box>
                )
            })}
        </Box>
    )
}

export default Mosaic
